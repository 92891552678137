<template>
  <div class="card">
    <form method="post" @submit.prevent="saveForm">
      <div class="card-content">
        <div class="columns view-columns">
          <div class="column">
            <b-field label="Typ">
              <b-select
                placeholder="Bitte wählen Sie den Typ"
                v-model.trim.lazy="$v.page.type.$model"
                :class="{ is_danger: $v.page.type.$error }"
                style="width:inherit"
              >
                <option
                  v-for="option in pageTypes"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field
              v-if="!pt"
              label="Sortierziffer"
              :type="{ 'is-danger': $v.page.sortno.$error }"
              :message="{
                'Bitte geben Sie eine Sortierziffer ein!':
                  submitted && $v.page.sortno.$error
              }"
            >
              <b-input v-model.trim.lazy="$v.page.sortno.$model"> </b-input>
            </b-field>
            <b-field
              v-if="pt"
              label="URL"
              :type="{ 'is-danger': $v.page.url.$error }"
              :message="{
                'Bitte geben Sie ein URL ein (z.B. about)!':
                  submitted && $v.page.url.$error
              }"
            >
              <b-input v-model.trim="$v.page.url.$model"> </b-input>
            </b-field>
            <b-field
              label="Seite freischalten"
              :type="{ 'is-danger': $v.page.status.$error }"
              :message="{
                '': submitted && $v.page.status.$error
              }"
            >
              <b-switch
             
                v-model.trim="$v.page.status.$model"
                true-value="1"
                false-value="0"
                size="is-small"
              >
              </b-switch
              >ist freigeschaltet
            </b-field>
             <b-field
              v-if="pt"
              label="Suchmaschinen"
              :type="{ 'is-danger': $v.page.nobots.$error }"
              :message="{
                '': submitted && $v.page.nobots.$error
              }"
            >
              <b-switch
                v-model.trim="$v.page.nobots.$model"
                true-value="1"
                false-value="0"
                size="is-small"
              >
              </b-switch
              >für Suchmaschinen gesperrt
            </b-field>
          </div>
        </div>
        <div class="columns  view-columns">
          <div class="column">
            <b-field
              label="Überschrift deutsch"
              :type="{ 'is-danger': $v.page.headline_de.$error }"
              :message="{
                'Bitte geben Sie eine Überschrift ein!':
                  submitted && $v.page.headline_de.$error
              }"
            >
              <b-input v-model.trim.lazy="$v.page.headline_de.$model">
              </b-input>
            </b-field>
            <b-field
              label="Inhalt deutsch"
              :type="{ 'is-danger': $v.page.html_de.$error }"
              :message="{
                'Bitte geben Sie einen Inhalt ein!':
                  submitted && $v.page.html_de.$error
              }"
            >
              <vue-trix
                id="editor_de"
                v-model.trim.lazy="$v.page.html_de.$model"
              ></vue-trix>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Überschrift englisch"
              :type="{ 'is-danger': $v.page.headline_en.$error }"
              :message="{
                'Bitte geben Sie eine Überschrift ein!':
                  submitted && $v.page.headline_en.$error
              }"
            >
              <b-input v-model.trim.lazy="$v.page.headline_en.$model">
              </b-input>
            </b-field>

            <b-field
              label="Inhalt englisch"
              :type="{ 'is-danger': $v.page.html_en.$error }"
              :message="{
                'Bitte geben Sie einen Inhalt ein!':
                  submitted && $v.page.html_en.$error
              }"
            >
              <vue-trix
                id="editor_de"
                v-model.trim.lazy="$v.page.html_en.$model"
              ></vue-trix>
            </b-field>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="column field is-grouped">
          <router-link
            to="/pages/index"
            class="button"
            style="margin-right: 10px;"
            custom
            v-slot="{ navigate }"
          >
            <button @click="navigate" role="link" class="button">
              Zurück zur Übersicht
            </button></router-link
          >

          <b-button
            class="button"
            type="is-info"
            native-type="submit"
            icon-left="cloud_upload"
            >Speichern</b-button
          >
        </div>
      </div>
    </form>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import {
  required,
  requiredIf,
  minLength,
  numeric
} from "vuelidate/lib/validators"
import VueTrix from "vue-trix"
export default {
  name: "pageForm",
  components: { VueTrix },
  data() {
    return {
      page: {
        type: "page",
        sortno: 0,
        url: "",
        headline_de: "",
        headline_en: "",
        html_de: "",
        html_en: "",
        status: 0,
        nobots: 0
      },
      pageTypes: [
        { id: "page", name: "Seite" },
        { id: "faq", name: "FAQ" }
      ],
      error: null,
      isLoading: false,
      submitted: false
    }
  },

  computed: {
    pt: function() {
      if (this.page.type == "faq") {
        return false
      }
      return true
    }
  },
  validations: {
    page: {
      headline_de: {
        required: required,
        minLength: minLength(5)
      },
      headline_en: {
        required: required,
        minLength: minLength(5)
      },
      html_de: {
        required: required,
        minLength: minLength(5)
      },
      html_en: {
        required: required,
        minLength: minLength(5)
      },
      url: {
        required: requiredIf(function(page) {
          return page.type == "page" ? true : false
        }),
        minLength: minLength(2)
      },
      sortno: {
        required: requiredIf(function(page) {
          return page.type == "faq" ? true : false
        }),
        numeric: numeric
      },
      type: { required: required, minLength: minLength(3) },
      status: { required },
      nobots:{}
    }
  },
  created: function() {
    var subheadline = ""

    var t = this
    if (typeof this.$route.params.id != "undefined") {
      subheadline = "Seite/FAQ bearbeiten: #" + this.$route.params.id

      t.isLoading = true
      var t = this
      t.$http({
        url: "/pages/view/" + this.$route.params.id,
        method: "get"
      })
        .then(function(resp) {
          t.page = resp.data.data
          t.isLoading = false
          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
        })
    } else {
      subheadline = "Neue Seite/FAQ anlegen"
    }

    this.$store.commit("setHeadline", {
      subheadline: subheadline
    })
  },

  methods: {
    saveForm: function() {
      //console.log("customer-daten")
      //console.log(this.page)
      this.error = null
      this.submitted = true
      this.$v.$touch()
      var t = this
      if (t.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        t.$parent.pageSave(t.page)
      }
    }
  }
}
</script>
<style>
#editor_de button.trix-button--icon-decrease-nesting-level,
#editor_de button.trix-button--icon-increase-nesting-level {
  display: none;
}
trix-editor.trix-content {
  background-color: #ffffff !important;
  min-height: 200px;
}
</style>
