<template>
  <div class="card">
    <div class="card-content">
      <div class="columns view-columns">
        <div class="column"></div>
      </div>
      <div class="columns  view-columns">
        <div class="column">
          <b-field label="Überschrift deutsch">
            {{ page.headline_de }}
          </b-field>
          <b-field label="Teaser deutsch">
            {{ page.teaser_de }}
          </b-field>
          <b-field label="Inhalt deutsch" v-html="page.html_de"> </b-field>
        </div>
        <div class="column">
          <b-field label="Überschrift englisch">
            {{ page.headline_en }}
          </b-field>

          <b-field label="Teaser deutsch">
            {{ page.teaser_en }}
          </b-field>

          <b-field label="Inhalt englisch" v-html="page.html_en"> </b-field>
        </div>
      </div>
    </div>

    <div class="card-footer ">
      <div class="column field is-grouped">
        <router-link
          to="/pages/index"
          class="button"
          style="margin-right: 10px;"
          custom
          v-slot="{ navigate }"
        >
          <button @click="navigate" role="link" class="button"
            >Zurück zur Übersicht</button
          ></router-link
        >

        <b-button
          @click.prevent="pageEdit(page.id, page.headline_de)"
          class="button is-info"
          icon-left="square-edit-outline"
          style="margin-right: 10px;"
          >Bearbeiten</b-button
        >
        <b-button
          @click.prevent="pageDelete(page.id, page.headline_de)"
          class="button is-danger"
          icon-left="delete_forever"
          >Löschen</b-button
        >
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
export default {
  name: "stockView",
  components: {},

  data: function() {
    return {
      isLoading: true,
      page: {},
      error: false,
      pageTypes: [
        { id: "page", name: "Seite" },
        { id: "faq", name: "FAQ" }
      ]
    }
  },
  created: function() {
    this.pageView(this.$route.params.id)
    this.$store.commit("setHeadline", {
      subheadline: "Seite ansehen "
    })
  },

  methods: {
    pageView: function(id) {
      var t = this
      t.$http({
        url: "/pages/view/" + id,
        method: "get"
      })
        .then(function(resp) {
          t.page = resp.data.data
          t.isLoading = false
          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
        })
    },
    getPageType(type) {
      for (var i = 0; i < this.pageTypes.length; i++) {
        if (this.pageTypes[i].id == type) {
          return this.pageTypes[i].name
        }
      }
      return "unbekannt"
    },
    pageDelete: function(id, name) {
      this.$parent.pageDelete(id, name)
    },
    customerEdit: function(id) {
      this.$parent.customerEdit(id)
    },
    pageEdit: function(id) {
      this.$router
        .push({
          path: "/pages/edit/" + id
        })
        .catch((err) => {})
    }
  }
}
</script>
<style>
div#status,
div#customer {
  margin-bottom: 30px;
}
</style>
