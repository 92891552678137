var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.saveForm.apply(null, arguments)}}},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Typ"}},[_c('b-select',{class:{ is_danger: _vm.$v.page.type.$error },staticStyle:{"width":"inherit"},attrs:{"placeholder":"Bitte wählen Sie den Typ"},model:{value:(_vm.$v.page.type.$model),callback:function ($$v) {_vm.$set(_vm.$v.page.type, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.page.type.$model"}},_vm._l((_vm.pageTypes),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1),(!_vm.pt)?_c('b-field',{attrs:{"label":"Sortierziffer","type":{ 'is-danger': _vm.$v.page.sortno.$error },"message":{
              'Bitte geben Sie eine Sortierziffer ein!':
                _vm.submitted && _vm.$v.page.sortno.$error
            }}},[_c('b-input',{model:{value:(_vm.$v.page.sortno.$model),callback:function ($$v) {_vm.$set(_vm.$v.page.sortno, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.page.sortno.$model"}})],1):_vm._e(),(_vm.pt)?_c('b-field',{attrs:{"label":"URL","type":{ 'is-danger': _vm.$v.page.url.$error },"message":{
              'Bitte geben Sie ein URL ein (z.B. about)!':
                _vm.submitted && _vm.$v.page.url.$error
            }}},[_c('b-input',{model:{value:(_vm.$v.page.url.$model),callback:function ($$v) {_vm.$set(_vm.$v.page.url, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.page.url.$model"}})],1):_vm._e(),_c('b-field',{attrs:{"label":"Seite freischalten","type":{ 'is-danger': _vm.$v.page.status.$error },"message":{
              '': _vm.submitted && _vm.$v.page.status.$error
            }}},[_c('b-switch',{attrs:{"true-value":"1","false-value":"0","size":"is-small"},model:{value:(_vm.$v.page.status.$model),callback:function ($$v) {_vm.$set(_vm.$v.page.status, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.page.status.$model"}}),_vm._v("ist freigeschaltet ")],1),(_vm.pt)?_c('b-field',{attrs:{"label":"Suchmaschinen","type":{ 'is-danger': _vm.$v.page.nobots.$error },"message":{
              '': _vm.submitted && _vm.$v.page.nobots.$error
            }}},[_c('b-switch',{attrs:{"true-value":"1","false-value":"0","size":"is-small"},model:{value:(_vm.$v.page.nobots.$model),callback:function ($$v) {_vm.$set(_vm.$v.page.nobots, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.page.nobots.$model"}}),_vm._v("für Suchmaschinen gesperrt ")],1):_vm._e()],1)]),_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Überschrift deutsch","type":{ 'is-danger': _vm.$v.page.headline_de.$error },"message":{
              'Bitte geben Sie eine Überschrift ein!':
                _vm.submitted && _vm.$v.page.headline_de.$error
            }}},[_c('b-input',{model:{value:(_vm.$v.page.headline_de.$model),callback:function ($$v) {_vm.$set(_vm.$v.page.headline_de, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.page.headline_de.$model"}})],1),_c('b-field',{attrs:{"label":"Inhalt deutsch","type":{ 'is-danger': _vm.$v.page.html_de.$error },"message":{
              'Bitte geben Sie einen Inhalt ein!':
                _vm.submitted && _vm.$v.page.html_de.$error
            }}},[_c('vue-trix',{attrs:{"id":"editor_de"},model:{value:(_vm.$v.page.html_de.$model),callback:function ($$v) {_vm.$set(_vm.$v.page.html_de, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.page.html_de.$model"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Überschrift englisch","type":{ 'is-danger': _vm.$v.page.headline_en.$error },"message":{
              'Bitte geben Sie eine Überschrift ein!':
                _vm.submitted && _vm.$v.page.headline_en.$error
            }}},[_c('b-input',{model:{value:(_vm.$v.page.headline_en.$model),callback:function ($$v) {_vm.$set(_vm.$v.page.headline_en, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.page.headline_en.$model"}})],1),_c('b-field',{attrs:{"label":"Inhalt englisch","type":{ 'is-danger': _vm.$v.page.html_en.$error },"message":{
              'Bitte geben Sie einen Inhalt ein!':
                _vm.submitted && _vm.$v.page.html_en.$error
            }}},[_c('vue-trix',{attrs:{"id":"editor_de"},model:{value:(_vm.$v.page.html_en.$model),callback:function ($$v) {_vm.$set(_vm.$v.page.html_en, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.page.html_en.$model"}})],1)],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"column field is-grouped"},[_c('router-link',{staticClass:"button",staticStyle:{"margin-right":"10px"},attrs:{"to":"/pages/index","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('button',{staticClass:"button",attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(" Zurück zur Übersicht ")])]}}])}),_c('b-button',{staticClass:"button",attrs:{"type":"is-info","native-type":"submit","icon-left":"cloud_upload"}},[_vm._v("Speichern")])],1)])]),_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }