<template>
  <b-table
    :data="data.data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    icon-pack="icm"
    :mobile-cards="false"
    :row-class="(row, index) => (row.status == 1) ? 'green' : 'red'"
  >
    
      <b-table-column v-slot="props"  field="Pages.id" label="Id" cell-class="status" numeric>
        {{ $utilities.padLeftWithNull(props.row.id) }}
      </b-table-column>

      <b-table-column v-slot="props"  field="Pages.type" label="Typ">
        {{ props.row.type == "faq" ? "FAQ" : "Seite" }}
      </b-table-column>

      <b-table-column v-slot="props"  field="Pages.headline_de" label="Titel">
        {{ props.row.headline_de }}
      </b-table-column>

      <b-table-column v-slot="props"  label="Addr./Sort">
        {{ props.row.type == "faq" ? props.row.sortno : props.row.url }}
      </b-table-column>

      <b-table-column v-slot="props"  field="Pages.updated_at" label="Zuletzt Bearb.">
        {{ $utilities.formatUnixtimeToDmYHi(props.row.updated_at) }} von
        {{ props.row.bouser_name }}
      </b-table-column>

      <b-table-column v-slot="props" 
        label="Ans."
        class="iconlink"
        field="link1"
        header-class="iconlink"
      >
        <a
          :href="`/pages/view/${props.row.id}`"
          title="Ansehen"
          @click.prevent="pageView(props.row.id)"
        >
          <b-icon icon="find_in_page"></b-icon>
        </a>
      </b-table-column>
      <b-table-column v-slot="props" 
        label="B"
        class="iconlink"
        field="link2"
        header-class="iconlink"
      >
        <a
          :href="`/pages/edit/${props.row.id}`"
          title="Bearbeiten"
          @click.prevent="pageEdit(props.row.id)"
          ><b-icon icon="square-edit-outline"></b-icon
        ></a>
      </b-table-column>

      <b-table-column v-slot="props" 
        label="L"
        class="iconlink"
        field="link3"
        header-class="iconlink"
      >
        <a
          @click.prevent="pageDelete(props.row.id, props.row.headline_de)"
          title="Löschen"
          ><b-icon icon="delete_forever"></b-icon
        ></a>
      </b-table-column>
  </b-table>
</template>

<script>
export default {
  data() {
    return {
      data: {
        count: 0,
        perpage: 50,
        data: [],
        page: 1,
        order: {
          field: "type",
          direction: "ASC"
        }
      },
      error: "",
      subtitle: "",
      isLoading: true,
      searchphrase: "",
      modal: false
    }
  },

  created: function() {
    this.pagesList()
  },
  methods: {
    pagesList: async function(data = null) {
      this.isLoading = true
      if (data != null) {
        //console.log('Daten übergeben')
        this.data = data
        this.isLoading = false
      } else {
        var t = this
        t.$http
          .post("/pages", {
            page: t.data.page,
            search: t.$parent.searchphrase,
            order: t.data.order
          })

          .then(function(resp) {
            t.data = resp.data.data
            t.isLoading = false
            return true
          })
          .catch(function(error) {
            //console.log(error)
            if (
              typeof error.response != "undefined" &&
              typeof error.response.data.message != "undefined"
            ) {
              t.error = error.response.data.message
            } else {
              t.error =
                "Server nicht erreichbar. Bitte versuchen Sie es erneut."
            }
          })
      }
    },
    pageView: function(id) {
      this.$router
        .push({
          path: "/pages/view/" + id
        })
        .catch((err) => {})
    },
    pageEdit: function(id) {
      this.$router
        .push({
          path: "/pages/edit/" + id
        })
        .catch((err) => {})
    },
    pageDelete: function(id, name) {
      this.$parent.pageDelete(id, name)
    }
  }
}
</script>
